import $ from 'jquery';
import _ from 'lodash';

import { getFeatureVariable } from '@optimizely/js-sdk-lab/src/actions';

import Promise from 'core-js/es6/promise';

import flux from 'core/flux';
import AjaxUtil from 'optly/utils/ajax';

import { enums as ChampagneEnums } from 'optly/modules/optimizely_champagne';

import PublishStatusActions from 'bundles/p13n/modules/publish_status/actions';
import { actions as CommitActions } from 'optly/modules/entity/commit';
import {
  actions as ExperimentSectionActions,
  getters as ExperimentSectionGetters,
} from 'optly/modules/entity/experiment_section';
import LayerExperiment from 'optly/modules/entity/layer_experiment';
import { actions as LiveCommitTagActions } from 'optly/modules/entity/live_commit_tag';
import { actions as VerifierActions } from 'optly/modules/verifier';
import {
  actions as EventActions,
  getters as EventGetters,
} from 'optly/modules/entity/event';
import { actions as ViewActions } from 'optly/modules/entity/view';
import RestApi from 'optly/modules/rest_api';
import Immutable from 'optly/immutable';
import { isCMABGroupedExperienceEnabled } from 'optly/utils/features';

import getters from './getters';
import * as fns from './fns';
import * as enums from './enums';
import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

/**
 * Creates a LayerExperiment associated with the given layer targeted at
 * the given audience
 *
 * @param {Number} layerId
 * @param {Number} audienceId
 * @param {Array} actions
 *
 * @returns {Deferred}
 */
function createExperimentForAudience(projectId, layerId, audienceId, actions) {
  return LayerExperiment.actions.save({
    project_id: projectId,
    layer_id: layerId,
    audience_ids: [audienceId],
    variations: [
      {
        name: 'Variation #1',
        actions: actions || [],
        weight: 10000,
      },
    ],
  });
}

/**
 * Creates a new layer experiment associated with the given continuous optimization layer
 * and updates the layer with that new layer experiment. This action uses audienceConditionsJson and ignores audienceConditions
 *
 * @param {Object} layerExperimentData
 * @param {Object} currentLayer
 * @returns {Deferred}
 */
// TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
function createExperimentForCOLayer(layerExperimentData, currentLayer) {
  const deferred = $.Deferred();

  return LayerExperiment.actions
    .save(layerExperimentData, { useAudienceConditionsJSON: true })
    .then(result => {
      const newExperimentIdList = currentLayer.experiment_ids;
      newExperimentIdList.push(result.id);

      const saveConfig = {
        id: currentLayer.id,
        experiment_ids: newExperimentIdList,
      };

      if (currentLayer.decision_metadata) {
        // Add new experiment in its own priority group, after all the existing
        // groups
        saveConfig.decision_metadata = fns.getDecisionMetadata(currentLayer);
        saveConfig.decision_metadata.experiment_priorities = currentLayer.decision_metadata.experiment_priorities.concat(
          [[result.id]],
        );
      }

      // set default weight 10000 for the new experiment - as it is initially ungrouped
      if (
        isCMABGroupedExperienceEnabled() &&
        saveConfig.decision_metadata.experiment_weights
      ) {
        saveConfig.decision_metadata.experiment_weights[result.id] = 10000;
      }

      return this.save(saveConfig).then(deferred.resolve());
    });
}

/**
 * Updates the existing layer experiment associated with the given continuous optimization layer
 * and updates the layer with that new layer experiment. This action uses audienceConditionsJson and ignores audienceConditions
 *
 * @param {Object} layerExperimentData
 */
function updateExperimentForCOLayer(layerExperimentData) {
  return LayerExperiment.actions.save(layerExperimentData, {
    useAudienceConditionsJSON: true,
  });
}

/**
 * Utility function which takes a layerId and a list of audiences to be associated with that layer.
 * Checks whether or not an experiment already exists for that audience, if not, a new experiment is created,
 * then a list of experiment_ids is fetched for all the audiences and stored as the experiment_id property
 * of the layer as well as on the decision_metadata.experiment_priorities property of the layer.
 **
 * NB: This should only be called when CREATING a Personalization layer
 *
 * @param {Number} layerId
 * @param {Array} audienceIds
 * @returns {Deferred}
 */
function syncAudiencesAndExperimentsForPersonalization(
  projectId,
  layerId,
  audienceIds,
) {
  const deferred = $.Deferred();
  const createLayerExperimentDeferreds = [];
  const experiments = flux.evaluateToJS(getters.experimentsByLayerId(layerId));

  const addedAudiences = _.filter(
    audienceIds,
    audienceId =>
      !_.find(experiments, exp => exp.audience_ids[0] === audienceId),
  );

  addedAudiences.forEach(audienceId => {
    // Explicitly using exports here to make it possible to stub this helper method.
    createLayerExperimentDeferreds.push(
      createExperimentForAudience(projectId, layerId, audienceId),
    );
  });

  Promise.all(createLayerExperimentDeferreds).then(() => {
    const layerExperiments = flux.evaluateToJS(
      getters.experimentsByLayerId(layerId),
    );
    const orderedExperiments = _.map(
      audienceIds,
      audienceId =>
        _.filter(layerExperiments, exp => exp.audience_ids[0] === audienceId)[0]
          .id,
    );

    // We need to keep experiment_ids for now since getters in the frontend rely on them, and
    // we still have ordered campaigns floating around.
    const experimentIds = _.uniq(orderedExperiments);
    // And we need to populate decision_metadata.experiment_priorities
    const experimentPriorities = _.uniq(
      orderedExperiments,
    ).map(experimentId => [experimentId]);

    const layer = flux.evaluateToJS(getters.byId(layerId));
    const decisionMetadata = fns.getDecisionMetadata(layer);
    decisionMetadata.experiment_priorities = experimentPriorities;

    this.save({
      id: layerId,
      decision_metadata: decisionMetadata,
      experiment_ids: experimentIds,
    }).then(deferred.resolve);
  });

  return deferred;
}

/**
 * Creates a new layer and provisions layer experiments as needed.
 *
 * @param {Object} layerData - The data to save for the new layer.
 * @param {Array} audienceIds - List of ordered audience ids for the layer.
 * @return {Deferred} Resolved when layer and each layer experiment has been saved
 */
function createPersonalizationLayer(layerData, audienceIds) {
  if (layerData.id) {
    throw new Error(
      `Found unexpected layer id ${layerData.id} preceding new Personalization Layer creation`,
    );
  }

  const deferred = $.Deferred();
  const initializedLayerData = _.extend(layerData, {
    policy: enums.policy.EQUAL_PRIORITY,
    decision_metadata: {
      experiment_priorities: [],
    },
    experiment_ids: [],
    status: enums.status.ACTIVE,
    type: enums.type.PERSONALIZATION,
  });

  // Create the layer, then go ahead and create a layer experiment for each audience
  this.save(initializedLayerData).then(newLayer => {
    this.syncAudiencesAndExperimentsForPersonalization(
      newLayer.project_id,
      newLayer.id,
      audienceIds,
    ).then(_.partial(deferred.resolve, newLayer));
  });

  return deferred;
}

/**
 * Creates a new Single-Layer Experiment.
 *
 * @param {Object} layerData - The data to save for the new layer.
 * @param {Array} layerExperimentData - The data to save for the associated layer experiment.
 * @return {Deferred} Resolved when layer and each layer experiment has been saved
 */
function createSingleABTestLayer(layerData, layerExperimentData) {
  if (layerData.id) {
    throw new Error(
      `Found unexpected layer id ${layerData.id} preceding new AB Test Layer creation`,
    );
  }

  const deferred = $.Deferred();
  const initializedLayerData = _.extend(layerData, {
    experiment_ids: [],
    status: enums.status.ACTIVE,
    type:
      layerExperimentData &&
      layerExperimentData.allocation_policy === 'min-regret'
        ? enums.type.MULTIARMED_BANDIT
        : enums.type.AB,
  });

  // Create the layer, then make a layerExperiment inside that layer.
  this.save(initializedLayerData).then(newLayer => {
    layerExperimentData.layer_id = newLayer.id;
    LayerExperiment.actions.save(layerExperimentData).then(experiment => {
      this.save({
        id: newLayer.id,
        experiment_ids: [experiment.id],
      }).then(() => {
        deferred.resolve({
          newLayer,
          experimentId: experiment.id,
        });
      });
    });
  });

  return deferred;
}

/**
 * Creates a new Multivariate Test. To accomplish this, we need to do the following in order:
 * 1) Create a Layer of policy type `multivariate`.
 * 2) Create an empty LayerExperiment (this is the destination layer experiment) with the correct
 *    audiences (if any), with its `layer_id` pointing to the layer we just created.
 * 3) Create all the ExperimentSections, with their `layer_id` pointing to the layer.
 * 4) Update the layer's `section_ids` list with the newly created ExperimentSections.
 *
 * @param {Object<Layer>} layerData - The data to save for the new layer.
 * @param {Object<LayerExperiment>} layerExperimentData - The layer experiment that needs to be saved.
 * @param {Array<ExperimentSection>} sectionsData - The experiment sections that need to be saved.
 * @return {Deferred} Resolved when layer, layer experiment, and sections have been saved.
 */
function createMultivariateTestLayer(
  layerData,
  layerExperimentData,
  sectionsData,
) {
  if (layerData.id) {
    throw new Error(
      `Found unexpected layer id ${layerData.id} preceding new MVT Layer creation`,
    );
  }

  const deferred = $.Deferred();
  const initializedLayerData = _.extend(layerData, {
    status: enums.status.ACTIVE,
    type: enums.type.MULTIVARIATE,
  });

  let newLayerId;
  let newLayer;
  this.save(initializedLayerData)
    .then(layer => {
      newLayer = layer;
      layerExperimentData.layer_id = newLayer.id;
      newLayerId = newLayer.id;
      return LayerExperiment.actions.save(layerExperimentData);
    })
    .then(layerExperiment => {
      const saveSectionsDeferreds = [];
      sectionsData.forEach(section => {
        section.layer_experiment_id = layerExperiment.id;
        saveSectionsDeferreds.push(ExperimentSectionActions.save(section));
      });
      return Promise.all(saveSectionsDeferreds).then(sections => {
        const sectionIds = sections.map(section => section.id);
        this.save({
          id: newLayerId,
          section_ids: sectionIds,
        }).then(() => {
          deferred.resolve({ newLayer, experimentId: layerExperiment.id });
        });
      });
    });
  return deferred;
}

function updateExperimentForSingleABTestLayer(layerData, layerExperimentData) {
  return this.save(layerData).then(() => {
    LayerExperiment.actions.save(layerExperimentData);
  });
}

/**
 * Performs an update to the layer passed in.
 *    - Saves the layer
 *    - Updates removed views and audiences.
 *
 * @param {Object} layerData - The updated data to save for the layer.
 * @param {Array} audienceIds - Ordered list of audience ids.
 * @return {Deferred} - Resolved when layer and each layer experiment has been saved
 */
function updateABTestLayer(layerData, audienceIds) {
  const deferred = $.Deferred();

  // Retrieve the saved layer
  const layer = flux.evaluateToJS(getters.byId(layerData.id));
  if (!layer) {
    deferred.reject();
    return deferred;
  }

  // Get a list of audience_ids associated with the layer in order
  const layerAudienceIds = flux
    .evaluate(getters.audienceIdsByLayerId(layerData.id))
    .toJS();

  // Get views that exist in saved layer but not in new layerData
  const removedViewIds = layerData.view_ids
    ? _.difference(layer.view_ids, layerData.view_ids)
    : [];

  // Get audiences that are in both new layerData and in saved layer data
  const existingAudienceIds = audienceIds
    ? _.intersection(audienceIds, layerAudienceIds)
    : layerAudienceIds;

  // Save the layer with the new data
  this.save(layerData).then(newLayer => {
    const experiments = flux.evaluate(
      getters.experimentsByLayerId(layerData.id),
    );
    const saveExperimentDeferreds = [];

    function experimentHasAudience(audiences) {
      return experiment =>
        _.difference(experiment.get('audience_ids').toJS(), audiences)
          .length === 0;
    }

    if (!_.isEqual(layerAudienceIds, audienceIds)) {
      const exp = experiments.first().set('audience_ids', audienceIds);
      saveExperimentDeferreds.push(LayerExperiment.actions.save(exp.toJS()));
    }

    // Remove deleted views from experiments corresponding to existing audiences
    if (removedViewIds.length > 0) {
      experiments
        .filter(experimentHasAudience(existingAudienceIds))
        .map(exp => {
          const updatedExperiment = LayerExperiment.fns.removeViews(
            exp,
            removedViewIds,
          );
          if (!updatedExperiment.equals(exp)) {
            saveExperimentDeferreds.push(
              LayerExperiment.actions.save(updatedExperiment.toJS()),
            );
          }
        });
    }

    // Resolve the returned deferred once all pending async actions have completed.
    if (saveExperimentDeferreds.length) {
      Promise.all(saveExperimentDeferreds).then(
        _.partial(deferred.resolve, newLayer),
      );
    } else {
      deferred.resolve(newLayer);
    }
  });

  return deferred;
}

/**
 * Overwrite the default delete and do a soft delete instead
 * @param {Layer} layer
 * @return {Deferred}
 */
function softDelete(layer) {
  return this.archive(layer);
}

/**
 * Archives (soft delete) a layer
 * @param {Layer} layer
 * @return {Deferred}
 */
function archive(layer) {
  return this.save({
    id: layer.id,
    archived: true,
  })
    .then(() => {
      if (layer.url_targeting && layer.url_targeting.view_id) {
        // Force fetch the view from the API because we expect a backend side effect to the view
        ViewActions.fetch(layer.url_targeting.view_id, true);
      }
      return layer;
    })
    .then(_.partial(archiveCampaignSpecificEvents, layer));
}

/**
 * Concludes a layer
 * @param {Object} layer
 * @param {number} layer.id
 * @param {string} layer.concluded_results_outcome
 * @param {string} layer.concluded_conclusions
 * @return {Deferred}
 */
function conclude(layer) {
  return this.save({
    id: layer.id,
    concluded: true,
    concluded_results_outcome: layer.concluded_results_outcome,
    concluded_conclusions: layer.concluded_conclusions,
  })
    .then(() => {
      if (layer.url_targeting && layer.url_targeting.view_id) {
        // Force fetch the view from the API because we expect a backend side effect to the view
        ViewActions.fetch(layer.url_targeting.view_id, true);
      }
      return layer;
    })
    .then(_.partial(archiveCampaignSpecificEvents, layer));
}

/**
 * Concludes and pauses a layer
 * @param {Object} layer
 * @param {string} result
 * @param {string} conclusions
 * @param {number} currentProjectId
 * @returns {Deferred}
 */
function concludeAndPause(layer, result, conclusions, currentProjectId) {
  PublishStatusActions.startPausing(layer.id);

  return this.conclude({
    ...layer,
    concluded_results_outcome: result,
    concluded_conclusions: conclusions,
  }).then(() => {
    LiveCommitTagActions.deactivateTag({
      layer_id: layer.id,
      // Purposefully don't pipe the result of the verifier as it takes
      // too long for the snippet to update
    }).then(updatedLiveCommit => {
      VerifierActions.verify({
        project_id: currentProjectId,
        revisionToVerify: updatedLiveCommit.project_code_revision,
      }); // don't error on rejection
    });
  });
}

/**
 * Concludes a layer and deploys its experiment
 * @param {Object} layer
 * @param {Object} conclusionValues
 * @param {String} conclusionValues.result
 * @param {String} conclusionValues.conclusions
 * @param {Number} experimentId
 * @param {Array<Number>} deployedVariationIds
 * @param {String} audienceConditions
 * @param {Number} layerHoldback
 * @param {Function} onSuccesAction
 * @returns {Deferred}
 */
function concludeAndDeployLayer(
  layer,
  conclusionValues,
  experimentId,
  deployedVariationIds,
  audienceConditions,
  layerHoldback,
) {
  const { result, conclusions } = conclusionValues;

  return this.conclude({
    ...layer,
    concluded_results_outcome: result,
    concluded_conclusions: conclusions,
  }).then(() =>
    LayerExperiment.actions.deploy({
      experimentId,
      deployedVariationIds,
      audienceConditions,
      layerHoldback,
    }),
  );
}

/**
 * Syncs the data after deploying an experiment
 * @param {Number} layerId
 * @param {Number} layerExperimentId
 * @param {String} layerType
 * @param {Number} projectId
 * @returns {Deferred}
 */
function syncDataAfterDeploy(layerId, layerExperimentId, layerType, projectId) {
  const fetchPromises = [
    baseEntityActions.fetch(layerId, true),
    LiveCommitTagActions.fetch({ layer_id: layerId }, true),
  ];
  if (layerType === enums.type.MULTIVARIATE) {
    fetchPromises.push(
      ExperimentSectionActions.fetchAll(
        {
          layer_experiment_id: layerExperimentId,
        },
        true,
      ),
    );
  }
  return Promise.all(fetchPromises).then(results => {
    const liveCommitTag = results[1];
    return Promise.all([
      CommitActions.fetch(liveCommitTag.commit_id, true),
      VerifierActions.verify({
        projectId,
        revisionToVerify: liveCommitTag.project_code_revision,
      }),
    ]);
  });
}

/**
 * Unarchives a layer
 * @param {Layer} layer
 * @return {Deferred}
 */
function unarchive(layer) {
  return this.save({
    id: layer.id,
    archived: false,
  })
    .then(() => {
      if (layer.url_targeting && layer.url_targeting.view_id) {
        // Force fetch the view from the API because we expect a backend side effect to the view
        ViewActions.fetch(layer.url_targeting.view_id, true);
      }
      return layer;
    })
    .then(_.partial(unarchiveCampaignSpecificEvents, layer));
}

/**

 * Duplicate an existing layer to a specified project
 * @param {Number} layer_id
 * @param {Number} project_id
 * @returns {Deferred}
 */
function duplicateToProject(layer_id, project_id) {
  const data = {
    layer_id,
    project_id,
  };

  return AjaxUtil.makeV1AjaxRequest({
    url: '/layer/duplicate',
    type: 'POST',
    data,
  });
}

/**
 * Update a layer's experiment priorities
 * @param {Number} layerId
 * @param {Array} experimentPriorities - An array of arrays, with each sub-array
 * representing a priority level, and containing experiment IDs
 * @return {Deferred}
 */
function updateExperimentPriorities(
  layerId,
  experimentPriorities,
  experimentWeights,
) {
  const layer = flux.evaluateToJS(getters.byId(layerId));
  const saveConfig = {
    id: layerId,
    policy: enums.policy.EQUAL_PRIORITY,
    decision_metadata: fns.getDecisionMetadata(layer),
  };
  saveConfig.decision_metadata.experiment_priorities = experimentPriorities;
  if (isCMABGroupedExperienceEnabled() && experimentWeights) {
    saveConfig.decision_metadata.experiment_weights = experimentWeights;
  }
  return this.save(saveConfig);
}

/**
 * @param {Number} layerId
 * @param {Array} newViewIds
 */
function updateLayerViews(layerId, newViewIds) {
  const preSaveLayer = flux.evaluate(getters.byId(layerId));
  const layerData = {
    id: layerId,
    view_ids: newViewIds,
  };
  const existingViewIds = preSaveLayer.get('view_ids').toJS();
  const removedViewIds = _.difference(existingViewIds, newViewIds);
  let def = this.save(layerData);
  if (removedViewIds.length > 0) {
    def = def
      .then(() => removeLayerExperimentOrSectionViews(layerId, removedViewIds))
      .then(() => {
        if (preSaveLayer.getIn(['url_targeting', 'view_id'])) {
          // If previous layer had url targeting fields, we
          // archive the url targeting view in the backend when the
          // views are updated. We need to force fetch the view
          // from the API to accurately reflect the archived state
          // in the UI
          ViewActions.fetch(
            preSaveLayer.getIn(['url_targeting', 'view_id']),
            true,
          );
        }
      });
  }
  return def;
}

/**
 * Wrapper for baseEntityActions.save() to allow invalidating cache after save.
 *
 * @param {Object} data
 * @param args
 * @returns {Promise}
 */
function save(data, ...args) {
  return baseEntityActions.save(data, ...args).then(response => {
    /**
     * Modifying and saving layer experiment will potentially update layer data, so we need to invalidate layer summary.
     */
    RestApi.actions.inValidateCacheDataByEntity({
      entity: 'layer_summaries',
      id: data.id,
    });
    return response;
  });
}

/**
 * @param {Number} layerId
 * @param {Object} urlTargetingConfig
 * @returns {Deferred} - resolves with the updated View that powers URL Targeting
 */
function saveOrUpdateLayerWithUrlTargeting(layerId, urlTargetingConfig) {
  const existingLayer = flux.evaluate(getters.byId(layerId));
  urlTargetingConfig = _.pick(urlTargetingConfig, enums.URL_TARGETING_FIELDS);
  const layerData = {
    id: layerId,
    url_targeting: urlTargetingConfig,
  };
  return this.save(layerData).then(updatedLayer =>
    ViewActions.fetch(updatedLayer.url_targeting.view_id, true).then(view => {
      const def = $.Deferred();
      if (
        !existingLayer.get('url_targeting') ||
        !existingLayer.get('url_targeting').size
      ) {
        const removedViewIds = existingLayer.get('view_ids').toJS();
        // If existing Layer did not have url targeting fields, the user is switching
        // from saved pages to url targeting. Remove all previous view_ids from the layer experiment
        removeLayerExperimentOrSectionViews(layerId, removedViewIds).then(
          () => {
            def.resolve(view);
          },
        );
      } else {
        def.resolve(view);
      }
      return def;
    }),
  );
}

/**
 * @param {Number} layerId
 * @param {Array} removedViewIds
 * @returns {Deferred}
 */
function removeLayerExperimentViews(layerId, removedViewIds) {
  const experimentSaveRequests = flux
    .evaluate(LayerExperiment.getters.entityCache)
    .filter(experiment => experiment.get('layer_id') === layerId)
    .map(experiment => {
      const updatedExperiment = LayerExperiment.fns.removeViews(
        experiment,
        removedViewIds,
      );
      if (updatedExperiment.equals(experiment)) {
        return $.Deferred().resolve();
      }
      return LayerExperiment.actions.save({
        id: updatedExperiment.get('id'),
        variations: updatedExperiment.get('variations'),
      });
    });
  return $.when(...experimentSaveRequests);
}

/**
 * @param {Number} layerId
 * @param {Array} removedViewIds
 * @returns {Deferred}
 */
function removeSectionViews(layerId, removedViewIds) {
  const sectionSaveRequests = flux
    .evaluate(ExperimentSectionGetters.entityCache)
    .filter(section => section.get('layer_id') === layerId)
    .map(section => {
      // LayerExperiment and ExperimentSection use same base entity, so 'removeViews' works with both
      const updatedSection = LayerExperiment.fns.removeViews(
        section,
        removedViewIds,
      );
      if (updatedSection.equals(section)) {
        return Promise.resolve();
      }
      return ExperimentSectionActions.save({
        id: updatedSection.get('id'),
        variations: updatedSection.get('variations'),
      });
    });
  return Promise.all(sectionSaveRequests);
}

/**
 * @param {Number} layerId
 * @param {Array} removedViewIds
 * @returns {Deferred}
 */
function removeLayerExperimentOrSectionViews(layerId, removedViewIds) {
  const existingLayer = flux.evaluate(getters.byId(layerId));
  const isMultivariateTestLayer = fns.isMultivariateTestLayer(existingLayer);
  return isMultivariateTestLayer
    ? removeSectionViews(layerId, removedViewIds)
    : removeLayerExperimentViews(layerId, removedViewIds);
}

/**
 * @param {Object} options
 * @param {Number} options.projectId
 * @param {String|Array<String>=} options.policies
 * @param {Boolean} [options.byPage=false] - Whether fetchAllPages should be used instead of fetchAll
 * @param {Boolean} [options.archived=false] - Whether to fetch archived or non-archived Layers
 * @return {Deferred|{firstPage: Deferred, allPages: Deferred}}
 */
function fetchAllByStatus({
  projectId,
  policies,
  byPage = false,
  archived = false,
}) {
  const opts = {
    skipEvaluatingCachedData: true,
  };
  const status = archived
    ? [enums.entityStatus.ARCHIVED]
    : [
        enums.entityStatus.NOT_STARTED,
        enums.entityStatus.PAUSED,
        enums.entityStatus.RUNNING,
        enums.entityStatus.CONCLUDED,
      ];

  const fetchFilters = {
    project_id: projectId,
    status,
    $order: 'last_modified:desc',
  };

  if (policies) {
    fetchFilters.policy = policies;
  }

  if (!byPage) {
    return this.fetchAll(fetchFilters, opts);
  }

  const {
    FEATURE_KEY,
    VARIABLES,
  } = ChampagneEnums.FEATURES.fetch_all_paginated;
  fetchFilters.$limit =
    getFeatureVariable(FEATURE_KEY, VARIABLES.page_size) ||
    RestApi.constants.DEFAULT_PAGE_SIZE;

  return this.fetchAllPages(fetchFilters, opts);
}

/**
 * @param {Object} layer
 * @param {Boolean} shouldArchive
 * @returns {Deferred}
 */
function modifyStatusOfCampaignSpecificEventsUsedAsMetrics(
  layer,
  shouldArchive,
) {
  const fullLayer = flux.evaluate(getters.byId(layer.id));
  if (!fullLayer.get('metrics')) {
    return $.Deferred().resolve();
  }

  let variationSpecificEventsFromMetrics = Immutable.Set();
  fullLayer.get('metrics').forEach(metric => {
    const event = flux.evaluate(EventGetters.byId(metric.get('event_id')));
    if (event && event.get('variation_specific')) {
      variationSpecificEventsFromMetrics = variationSpecificEventsFromMetrics.add(
        event,
      );
    }
  });

  return $.when(
    variationSpecificEventsFromMetrics.map(event => {
      if (shouldArchive) {
        const isEventUsedInAnotherUnarchivedCampaign = flux
          .evaluate(getters.entityCache)
          .filter(
            filterLayer =>
              filterLayer.get('id') !== layer.id &&
              !filterLayer.get('archived') &&
              !fns.hasLayerConcluded(filterLayer),
          )
          .some(
            someLayer =>
              someLayer.get('metrics') &&
              someLayer
                .get('metrics')
                .some(metric => metric.get('event_id') === event.get('id')),
          );
        if (isEventUsedInAnotherUnarchivedCampaign) {
          return $.Deferred().resolve();
        }
        return EventActions.archive(event.toJS());
      }

      return EventActions.unarchive(event.toJS());
    }),
  );
}

/**
 * @param {Object} layer
 * @returns {Deferred}
 */
function archiveCampaignSpecificEvents(layer) {
  return modifyStatusOfCampaignSpecificEventsUsedAsMetrics(layer, true);
}

/**
 * @param {Object} layer
 * @returns {Deferred}
 */
function unarchiveCampaignSpecificEvents(layer) {
  return modifyStatusOfCampaignSpecificEventsUsedAsMetrics(layer, false);
}

export default {
  ...baseEntityActions,
  archive,
  conclude,
  concludeAndDeployLayer,
  concludeAndPause,
  createExperimentForCOLayer,
  createPersonalizationLayer,
  createSingleABTestLayer,
  createMultivariateTestLayer,
  delete: softDelete,
  duplicateToProject,
  fetchAllByStatus,
  syncAudiencesAndExperimentsForPersonalization,
  unarchive,
  updateABTestLayer,
  updateExperimentPriorities,
  updateExperimentForCOLayer,
  updateExperimentForSingleABTestLayer,
  updateLayerViews,
  save,
  saveOrUpdateLayerWithUrlTargeting,
  syncDataAfterDeploy,
  removeSectionViews,
  removeLayerExperimentViews,
  removeLayerExperimentOrSectionViews,
};
