/**
 * Utils for regular expressions
 *
 * @author Jon Noronha (jon.noronha@optimizely.com)
 */

// named exports

/* eslint-disable no-useless-escape */

// Finds all matching URLs. From https://github.com/component/regexps/blob/master/index.js#L3
export const urls = /(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2}))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?/gi;

// Based on: http://stackoverflow.com/a/3809435, modified to allow top level domains >4 characters long.
export const url = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

export const hashtags = /#\w*/g;

export const email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Taken from a stackoverflow article on phone number rexes
export const phoneNumber =
  '(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*(' +
  '[2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)' +
  '|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*' +
  '(?:[.-]\\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})' +
  '\\s*(?:[.-]\\s*)?([0-9]{4})' +
  '(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?';

export const token = /^[a-z0-9]+$/i;

// Matches valid API names for Custom experiments and variations
export const apiName = /^[a-z0-9_\-]{1,64}$/i;

// Matches valid variable names for FullStack experiments
// Must stay in sync with what we have in src/www/constants/experimentation.py
// https://github.com/optimizely/optimizely/blob/8fbea63976324566eef525fce4c2e997d3a24e3d/src/www/constants/experimentation.py#L12
export const variableName = /^[a-z0-9_-]{1,64}$/i;

// Inverse of variableName, specifies which characters are not allowed
// Used to replace disallowed characters with allowed
// https://github.com/optimizely/optimizely/blob/8fbea63976324566eef525fce4c2e997d3a24e3d/src/www/constants/experimentation.py#L12
export const variableNameDisallowedChars = /[^a-z0-9_-]|\s+/gi;

// Matches valid API names for custom events
export const eventAPIName = /^[^\\?"'`]{1,64}$/i;

// Used to replace most special characters characters with  underscores on autoformat
export const eventAPINameDisallowedChars = /[^a-z0-9-]/gi;

// When event keys are manually edited, the only characters that are disallowed are backslashes, question marks, double quotes, single quotes, and backticks
export const manuallyEditedEventAPINameDisallowedChars = /[\\?"'`]/g;

// Matches valid file names
export const fileName = /^[A-Za-z\d_]+$/;

// Matches whitespaces
export const whiteSpaceOnly = /^\s*$/;

/* eslint-enable no-useless-escape */

export default {
  urls,
  url,
  hashtags,
  email,
  phoneNumber,
  token,
  apiName,
  variableName,
  variableNameDisallowedChars,
  eventAPIName,
  eventAPINameDisallowedChars,
  manuallyEditedEventAPINameDisallowedChars,
  fileName,
  whiteSpaceOnly,
};
