import keyMirror from 'optly/utils/key_mirror';

// TODO (gloria): REC-357
export const TrafficAllocationPolicyTypes = {
  MANUAL: 'manual',
  MAXIMIZE_CONVERSIONS: 'min-regret', // MAB
  MINIMIZE_TIME: 'min-time',
  CMAB: 'contextual-multi-arm-bandit',
};

export const ManualPolicyItem = {
  label: tr('Manual'),
  value: TrafficAllocationPolicyTypes.MANUAL,
  description: tr(
    'Set traffic distribution for each variation in this experiment.',
  ),
  header: '',
  test: 'manual-policy',
};

export const MultiArmedBanditPolicyItem = {
  label: tr('Multi-armed Bandit'),
  value: TrafficAllocationPolicyTypes.MAXIMIZE_CONVERSIONS,
  description: tr(
    'Quickly identify and show more traffic to the best-performing variation to optimize the impact based on the primary metric.',
  ),
  test: 'min-regret-policy',
};

export const ContextualMultiArmedBanditPolicyItem = {
  label: tr('Contextual Bandit'),
  value: TrafficAllocationPolicyTypes.CMAB,
  description: tr(
    'Deliver personalized variations given visitor context by dynamically allocating traffic using AI.',
  ),
  header: 'Automation',
  test: 'contextual-multi-arm-bandit-policy',
};

export const StatsAcceleratorPolicyItem = {
  label: tr('Stats Accelerator'),
  value: TrafficAllocationPolicyTypes.MINIMIZE_TIME,
  description: tr(
    'Traffic will be dynamically managed to reach statistical significance faster.',
  ),
  header: '',
  test: 'min-time-policy',
};

export const TrafficAllocationPolicyItems = [
  ManualPolicyItem,
  MultiArmedBanditPolicyItem,
  ContextualMultiArmedBanditPolicyItem,
  StatsAcceleratorPolicyItem,
];

export const TrafficAllocationMessages = {
  FULLSTACK:
    'When using automated settings, Stats Accelerator will automatically adjust the traffic distribution based on ' +
    'your experiment data and your results will reflect this. In Full Stack experiments, it is important to make sure users see ' +
    'the same variation throughout the experiment.',
  MANUAL: 'Change the percentage of visitors who see any given variation.',
  MAXIMIZE_CONVERSIONS:
    "Your traffic distribution will be automatically optimized for the primary metric. Traffic distribution can't be manually edited.",
  MINIMIZE_TIME:
    'Optimizely will automatically adjust Traffic Distribution as experiment data is collected to accelerate reaching statistical significance for the Primary Metric. Traffic Distribution cannot be manually edited.',
  CMAB: 'Help me choose a',
  MVT:
    'Stats Accelerator and Multi-Armed Bandits require Partial Factorial Mode. If you’d like to use Full Factorial Mode, switch to Manual Distribution Mode.',
};

export const TrafficAllocationVariationStatuses = keyMirror({
  STOPPED: null,
  STOPPING: null,
  LIVE: null,
});

export default {
  ManualPolicyItem,
  MultiArmedBanditPolicyItem,
  ContextualMultiArmedBanditPolicyItem,
  StatsAcceleratorPolicyItem,
  TrafficAllocationPolicyTypes,
  TrafficAllocationPolicyItems,
  TrafficAllocationMessages,
  TrafficAllocationVariationStatuses,
};
