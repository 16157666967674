import _ from 'lodash';

import guid from 'optly/utils/guid';
import error from 'optly/services/error';

/**
 * @author Tyler Brandt (tyler@optimizely.com)
 */
import htmlTemplate from './error.html';

const errorTitles = {
  FORM_VALIDATION_ERROR: tr('Validation Error'),
  SOMETHING_WENT_WRONG: tr('Something went wrong'),
  CONNECTION_TIMEOUT: tr('Request Timeout'),
  AUTH_ERROR: tr('Access was denied'),
  CMAB_ERROR: tr('Expand Your CMAB Capacity'),
};

const CMAB_LIMIT_ERROR_MESSAGE_PART =
  'You have reached the limit for active Contextual Bandits';

export default {
  template: htmlTemplate,

  data: {
    errorDetails: {},
    errorId: null,
    errorUrl: null,
    isServerOriginated: null,
    message: null,
    timestamp: null,
    type: null,
    data: null,
    response: null,
    logToServer: false,
    _onClose: null,
  },

  computed: {
    isFormValidationError() {
      return (
        this.errorDetails &&
        this.errorDetails.statusCode &&
        this.errorDetails.statusCode === 400
      );
    },

    isConnectionError() {
      return (
        this.errorDetails &&
        Object.prototype.hasOwnProperty.call(this.errorDetails, 'statusCode') &&
        this.errorDetails.statusCode === 0
      );
    },

    isAuthError() {
      return (
        this.errorDetails &&
        this.errorDetails.statusCode &&
        this.errorDetails.statusCode === 403
      );
    },

    isCmabError() {
      return (
        this.errorDetails?.statusCode === 409 &&
        this.message?.includes(CMAB_LIMIT_ERROR_MESSAGE_PART)
      );
    },

    title() {
      if (this.isFormValidationError) {
        return errorTitles.FORM_VALIDATION_ERROR;
      }
      if (this.isConnectionError) {
        return errorTitles.CONNECTION_TIMEOUT;
      }
      if (this.isAuthError) {
        return errorTitles.AUTH_ERROR;
      }
      if (this.isCmabError) {
        return errorTitles.CMAB_ERROR;
      }
      return errorTitles.SOMETHING_WENT_WRONG;
    },
  },

  methods: {
    /**
     * Send the error to the logging service
     *
     * @private
     */
    _logError() {
      if (this.logToServer) {
        error.logToServer(
          'JSError',
          _.extend({}, this.errorDetails, {
            errorId: this.errorId,
            errorUrl: this.errorUrl,
            errorMessage: this.message,
            timestamp: this.timestamp,
            type: this.type,
            response: this.response,
            data: this.data,
            wasErrorDialogShown: true,
            isServerOriginated: this.isServerOriginated,
          }),
        );
      }
    },
  },

  afterDestroy() {
    if (this._onClose) {
      this._onClose.resolve();
    }
  },

  ready() {
    this.isServerOriginated = !!this.errorId;
    this.errorId = this.errorId || guid();
    this.message =
      this.message ||
      tr(
        "Don't worry, most issues are minor - please refresh your browser and try again.",
      );
    this.timestamp = new Date().toISOString();
    this._logError();
  },
};
