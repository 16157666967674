import {
  api as SegmentJSAPI,
  core as SegmentJSCore,
} from '@optimizely/segment-js';

import flux from 'core/flux';
import locationHelper from 'optly/location';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import ProjectFns from 'optly/modules/entity/project/fns';
import { getters as UIVersionGetters } from 'optly/modules/ui/version';
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import { actions as OptimizelyChampagneActions } from 'optly/modules/optimizely_champagne';

import { isTemporaryOptimizelyEmail } from 'optly/utils/is_temp_optimizely_email';

// This should probably be an enum in core/router
const PAGE_LOAD_PATH = 'PAGE LOAD';

function getOrganizationId(accountInfo) {
  return accountInfo?.subscription?.turnstile_organization_id ?? null;
}

function getInstanceId(accountInfo) {
  return accountInfo?.subscription?.turnstile_instance_id ?? null;
}

function getInstanceName(accountInfo) {
  return accountInfo?.company_name ?? '';
}

function getExpAccountId(accountInfo) {
  return accountInfo?.account_id ?? '';
}

function getUserId(accountInfo) {
  return accountInfo?.email ?? '';
}

function getUserName(accountInfo) {
  const firstName = accountInfo?.first_name ?? '';
  const lastName = accountInfo?.last_name ?? '';
  return `${firstName} ${lastName}`.trim();
}

function getFirstName(accountInfo) {
  return accountInfo?.first_name ?? '';
}

function getLastName(accountInfo) {
  return accountInfo?.last_name ?? '';
}

function getGroupId(accountInfo) {
  return accountInfo?.subscription?.crm_id ?? accountInfo?.account_id ?? '';
}

function getIsTrialUser(accountInfo) {
  return accountInfo?.plan_id?.startsWith('free_') ?? false;
}

function getHasTurnstile(accountInfo) {
  return !!getInstanceId(accountInfo);
}

function getIsOptiUser(accountInfo) {
  return accountInfo?.email?.endsWith('@optimizely.com') ?? false;
}

function getPlanId(accountInfo) {
  return accountInfo?.plan_id ?? '';
}

function getPlanName(accountInfo) {
  return accountInfo?.plan_display_name ?? '';
}

function getMixpanelGroupProps(accountInfo) {
  return {
    groupId: getGroupId(accountInfo),
    organizationId: getOrganizationId(accountInfo),
    instanceId: getInstanceId(accountInfo),
    instanceName: getInstanceName(accountInfo),
    expAccountId: getExpAccountId(accountInfo),
    isTrialUser: getIsTrialUser(accountInfo),
    hasTurnstile: getHasTurnstile(accountInfo),
  };
}

/**
 * A function to retrieve the global data object for Segment.
 * This is data that will be sent with every tracking call
 * whose information is always available from the same flux stores.
 */
const getGlobalProperties = (accountInfo, projectInfo, isImpersonating) => {
  const projectType = ProjectFns.getProjectType(
    flux.evaluate(CurrentProjectGetters.project),
  );

  const SubProductNameMap = {
    web: 'Web Experimentation',
    custom: 'Feature Experimentation',
  };
  const subProductName = SubProductNameMap[projectType] || projectType;

  return {
    accountName: flux.evaluateToJS(AdminAccountGetters.accountName),
    stack: flux.evaluate(UIVersionGetters.activeVersion),
    url: locationHelper.getLocation(),
    path: locationHelper.getPath(),
    projectId: flux.evaluateToJS(CurrentProjectGetters.id),
    role: flux.evaluateToJS(AdminAccountGetters.currentRole),
    projectType,
    // Mixpanel super-props [FSSDK-10206]
    userId: getUserId(accountInfo),
    groupId: getGroupId(accountInfo),
    organizationId: getOrganizationId(accountInfo),
    instanceId: getInstanceId(accountInfo),
    instanceName: getInstanceName(accountInfo),
    productName: 'Experiment',
    subProductName,
    expAccountId: getExpAccountId(accountInfo),
    userName: getUserName(accountInfo),
    firstName: getFirstName(accountInfo),
    lastName: getLastName(accountInfo),
    productSku: JSON.stringify(accountInfo?.subscription?.product_ids ?? []),
    isTrialUser: getIsTrialUser(accountInfo),
    isOptiUser: getIsOptiUser(accountInfo),
    isImpersonating,
  };
};

/**
 * A function to retreive the global options object for Segment.
 * This is data that will be sent with every tracking call.
 */
const getGlobalOptions = () => {
  /**
   * Default Optimizely options for sending a track call with Segment.
   * This data is stored in the js-sdk-labs store.
   * This is consumed by their analytics.js integration:
   *   https://github.com/segment-integrations/analytics.js-integration-optimizely/blob/master/lib/index.js
   */
  const client = OptimizelyChampagneActions.getClientInstance();
  const user = client ? client.user : null;
  return {
    Optimizely: {
      userId: user?.id ?? null,
      attributes: user?.attributes ?? {},
    },
  };
};

/**
 * Calls functions which send group data and establish global properties for Segment
 * @param {Object} accountInfo
 * @param {Object} projectInfo
 * @param {Boolean} isImpersonating
 */
export function initialize(accountInfo, projectInfo, isImpersonating) {
  if (!isTemporaryOptimizelyEmail(accountInfo?.email ?? '')) {
    SegmentJSCore.setGlobalPropertiesFn(() =>
      getGlobalProperties(accountInfo, projectInfo, isImpersonating),
    );
    SegmentJSCore.setGlobalOptionsFn(getGlobalOptions);
    this.segmentGroupByProject(accountInfo, projectInfo, isImpersonating);
    this.segmentGroupByAccount(accountInfo, isImpersonating);
  }
}

/**
 * Identify and track event when a user logs in.
 * https://segment.com/docs/sources/website/analytics.js/#identify
 * @param {Object} accountInfo
 * @param {Boolean} isImpersonating
 * @param {Object} traits
 */
export function segmentIdentify(accountInfo, isImpersonating, traits) {
  if (!isTemporaryOptimizelyEmail(accountInfo?.email ?? '')) {
    const segmentAnalyticsGlobal = SegmentJSCore.getSegmentGlobal();
    // change userId to email for mixpanel support [FSSDK-10273]
    const prefix = isImpersonating ? 'impersonated_' : '';
    const userId = prefix + getUserId(accountInfo);

    try {
      if (segmentAnalyticsGlobal) {
        const mixpanelprops = {
          userId: getUserId(accountInfo),
          groupId: getGroupId(accountInfo),
          organizationId: getOrganizationId(accountInfo),
          instanceId: getInstanceId(accountInfo),
          instanceName: getInstanceName(accountInfo),
          userName: getUserName(accountInfo),
          // this redundant property with "name" key is for mixpanel support [FSSDK-10273]
          name: getUserName(accountInfo),
          firstName: getFirstName(accountInfo),
          lastName: getLastName(accountInfo),
          isTrialUser: getIsTrialUser(accountInfo),
          isOptiUser: getIsOptiUser(accountInfo),
          planId: getPlanId(accountInfo),
          planName: getPlanName(accountInfo),
          expAccountId: getExpAccountId(accountInfo),
        };

        const mergedProps = {
          ...mixpanelprops,
          ...traits,
        };

        segmentAnalyticsGlobal.identify(userId, mergedProps);
      }
    } catch (e) {
      console.warn(
        // eslint-disable-line
        'segmentIdentify call failed with accountInfo: ',
        accountInfo,
      );
      if (window.datadog) {
        const error = new Error('Exception during segmentIdentify', {
          error: e,
        });
        window.datadog.addError(error);
      }
    }
  }
}

/**
 * https://segment.com/docs/sources/website/analytics.js/#group
 * @param {Object} accountInfo
 * @param {Boolean} isImpersonating
 */
export function segmentGroupByAccount(accountInfo, isImpersonating) {
  const segmentAnalyticsGlobal = SegmentJSCore.getSegmentGlobal();
  const prefix = isImpersonating ? 'impersonated_' : '';
  try {
    if (segmentAnalyticsGlobal) {
      const accountName =
        flux.evaluateToJS(AdminAccountGetters.accountName) ?? '';

      const id = getGroupId(accountInfo);

      const groupProps = {
        name: accountName,
        description:
          accountInfo?.account_name ??
          accountInfo?.email?.replace(/^[^@]*@/, '') ??
          '',
        grouping: 'Account',
        planId: getPlanId(accountInfo),
        planName: getPlanName(accountInfo),
        isImpersonating,
      };

      const mergedProps = {
        ...groupProps,
        ...getMixpanelGroupProps(accountInfo),
      };

      segmentAnalyticsGlobal.group(prefix + id, mergedProps);
    }
  } catch (e) {
    console.warn(
      // eslint-disable-line
      'segmentAnalyticsGlobal.group() call failed with accountInfo: ',
      accountInfo,
    );
  }
}

/**
 * https://segment.com/docs/sources/website/analytics.js/#group
 * @param {Object} projectInfo
 * @param {Boolean} isImpersonating
 */
export function segmentGroupByProject(
  accountInfo,
  projectInfo,
  isImpersonating,
) {
  const segmentAnalyticsGlobal = SegmentJSCore.getSegmentGlobal();
  const prefix = isImpersonating ? 'impersonated_' : '';
  try {
    if (segmentAnalyticsGlobal) {
      const groupProps = {
        description: projectInfo?.project_name ?? '',
        grouping: 'Project',
        projectType:
          projectInfo?.project_platforms?.[0] === 'web'
            ? 'web'
            : projectInfo?.sdks?.[0] ??
              projectInfo?.project_platforms?.[0] ??
              '',
        isImpersonating,
      };

      const mergedProps = {
        ...groupProps,
        ...getMixpanelGroupProps(accountInfo),
      };

      segmentAnalyticsGlobal.group(prefix + projectInfo?.id ?? '', mergedProps);
    }
  } catch (e) {
    console.warn(
      // eslint-disable-line
      'segmentAnalyticsGlobal.group() call failed with projectInfo: ',
      projectInfo,
    );
  }
}

/**
 * Helper function to handle a route start.
 */
export function onRouteStart() {
  SegmentJSCore.resetProperties();
}

/**
 * Helper function for invoking Segment's page method with an Optimizely specific referrer value.
 * @param {Object} data
 */
export function trackRouteComplete(data) {
  const segmentAnalyticsGlobal = SegmentJSCore.getSegmentGlobal();
  const { routeMetadata = {} } = data;

  try {
    if (segmentAnalyticsGlobal) {
      let referrer = locationHelper.getOrigin();
      if (data.fromPath !== PAGE_LOAD_PATH) {
        referrer += data.fromPath;
      }
      SegmentJSAPI.page(routeMetadata.category, routeMetadata.name, {
        referrer,
      });
      SegmentJSCore.setProperties({ page: routeMetadata.name });
    }
  } catch (e) {
    console.warn('SegmentJSAPI.page() call failed with data: ', data); // eslint-disable-line
  }
}

/**
 * Helper function for invoking Segment's track method with an Optimizely specific referrer value.
 * @param event
 * @param properties
 * @param options
 */
export function trackEvent(eventName, properties = {}, options = {}) {
  const segmentAnalyticsGlobal = SegmentJSCore.getSegmentGlobal();
  try {
    if (segmentAnalyticsGlobal) {
      SegmentJSAPI.track(eventName, properties, options);
    }
  } catch (e) {
    console.warn('SegmentJSAPI.track() call failed with eventName: ', eventName); // eslint-disable-line
  }
}

export default {
  initialize,
  onRouteStart,
  segmentIdentify,
  segmentGroupByAccount,
  segmentGroupByProject,
  trackRouteComplete,
  trackEvent,
};
