import flux from 'core/flux';

import RestApi from 'optly/modules/rest_api';

import SaveLayerActions from 'optly/modules/entity/layer/actions_fns/save';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';

import entityDef from './entity_definition';
import getters from './getters';

const baseEntityActions = RestApi.createEntityActions(entityDef);
const actionEntityActions = RestApi.createEntityActions(
  Object.assign({}, entityDef, {
    isRelationshipEntity: true,

    /* Denotes this is a subresource of layer_experiment per the parents below. */
    isSubresource: true,

    parents: [
      {
        entity: 'experiment_sections',
        key: 'id',
      },
      {
        entity: 'variations',
        key: 'variation_id',
      },
      {
        entity: 'views',
        entityName: 'actions',
        key: 'view_id',
      },
    ],
  }),
);

export default {
  ...baseEntityActions,

  /**
   * Saves an action after pruning the status field from all changes.
   * @param {Objec} action
   * @return {Deferred}
   */
  cleanAndSaveAction(action, ...args) {
    action.changes.forEach(c => {
      delete c.status;
    });
    return actionEntityActions.save(action, ...args);
  },

  /**
   * Saves a section after pruning unnecessary fields
   * @param {Section} section
   * @return {Deferred}
   */
  save(section) {
    const sectionToSave = LayerExperimentFns.cleanActionsJSON(section);
    return baseEntityActions.save(sectionToSave);
  },

  archive(section) {
    return this.save({
      id: section.id,
      archived: true,
    });
  },

  /**
   * Deletes a section, which actually archives it and removes it from the section_ids property in the layer
   * @param {Section} section
   * @param {Layer} currentLayer
   * @return {Deferred}
   */
  deleteSection(section, currentLayer) {
    const archiveDef = this.archive(section);
    const updatedSectionIds = currentLayer.section_ids.filter(
      id => id !== section.id,
    );

    const layerUpdateDef = SaveLayerActions.save({
      id: currentLayer.id,
      section_ids: updatedSectionIds,
    });

    return Promise.all([archiveDef, layerUpdateDef]);
  },

  /**
   * Creates a new variation and redistributes traffic in the section
   * @param {String} variationName
   * @param {Object} section
   * @returns {Deferred}
   */
  createNewVariationWithRedistributedTraffic(variationName, section) {
    const newVariation = {
      name: variationName,
      actions: [],
    };

    const clonedSection = Object.assign({}, section);
    clonedSection.variations.push(newVariation);
    clonedSection.variations = LayerExperimentFns.redistributeWithNewVariationTraffic(
      clonedSection.variations,
    );
    return this.save(clonedSection);
  },

  /**
   * Rename a variation in a section
   * @param {Number} sectionId
   * @param {Number} variationId
   * @param {String} newName
   * @return {Deferred}
   */
  renameVariationInSection(sectionId, variationId, newName) {
    const updatedVariations = flux
      .evaluate(getters.byId(sectionId))
      .get('variations')
      .map(variation => {
        if (variation.get('variation_id') === variationId) {
          return variation.set('name', newName);
        }
        return variation;
      });
    return this.save({
      id: sectionId,
      variations: updatedVariations.toJS(),
    });
  },

  /**
   * save variation description in a section
   * @param {Number} sectionId
   * @param {Array} variations
   * @return {Deferred}
   */
  variationDescriptionInSection(sectionId, variations) {
    return this.save({
      id: sectionId,
      variations,
    });
  },

  /**
   * Delete a variation given the variation's section and the variation's ID
   * @param {ExperimentSection} section
   * @param {Number} variationId
   * @return {Deferred}
   */
  deleteVariationById(section, variationId) {
    const variations = section.get('variations');
    const removeIndex = variations.findIndex(
      variation => variation.get('variation_id') === variationId,
    );
    const weightOfStoppedVariation = variations.getIn([removeIndex, 'weight']);
    let newVariations = variations.splice(removeIndex, 1);
    newVariations = LayerExperimentFns.redistributeStoppedVariationTraffic(
      newVariations.toJS(),
      weightOfStoppedVariation,
    );
    return this.save({
      id: section.get('id'),
      variations: newVariations,
    });
  },
};
