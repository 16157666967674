import config from 'atomic-config';

import { forEach } from 'lodash';
import jsCookie from 'js-cookie';

import { actions as jsSDKLabActions } from '@optimizely/js-sdk-lab';
import {
  eventDispatcher as defaultEventDispatcher,
  setLogLevel as reactSDKSetLogLevel,
} from '@optimizely/react-sdk';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import flux from 'core/flux';

import { hash } from 'optly/utils/hasher';
import locationHelper from 'optly/location';
import parseQueryParams from 'optly/utils/parse_query_params';

import actionTypes from './action_types';
import fns from './fns';

const {
  consoleWarn,
  getClientInstance,
  setForcedVariation,
  setForcedVariationsFromUrlParams,
  track,
} = jsSDKLabActions;

const OPTIMIZELY_LOG_PARAM = 'js_sdk_log';
let unobserveUserState;

function observeUserState() {
  if (unobserveUserState) {
    unobserveUserState();
  }
  unobserveUserState = getClientInstance().onUserUpdate(userState => {
    flux.dispatch(actionTypes.SET_REACT_SDK_USER_STATE, hash(userState));
  });
}

/**
 * Helper method to return a custom event dispatcher which
 * no-ops if the cookie green=true is present (BDD test runner)
 * @return {Object}
 */
export const getEventDispatcher = () => ({
  dispatchEvent: (...args) => {
    if (/* bdd */ jsCookie.get('green') === 'true' || __DEV__ || __TEST__) {
      consoleWarn(
        this,
        'JS-SDK not logging event due to test environment:',
        ...args,
      );
      return;
    }
    defaultEventDispatcher.dispatchEvent(...args);
  },
});

/**
 * Read the optimizely_log= query param and set the corresponding log level for the JS-SDK.
 */
export const setLogLevel = () => {
  const params = parseQueryParams(locationHelper.getSearch());
  const logParam = params[OPTIMIZELY_LOG_PARAM];
  // Fall back to environment-determined default
  const defaultLogLevel = config.get('fullstack_log_level') || 'error';
  reactSDKSetLogLevel(logParam || defaultLogLevel);
};

/**
 * Register a userId for the feature provided.
 * Allows for consistent decision making & tracking when an arbitrary/non-standard userId
 * is desired to be used (like a new uuid created each time an API call is made).
 *
 * I.e.:
 *
 *    // Create an id, save it, and make a decision with it:
 *    const options = { userId: guid() };
 *    // Register our guid as the userId for this feature so we can bucket & track on a per usage basis.
 *    setUserIdForFeature('intelligent_fetchall_default_filter', options.userId);
 *    featureFunction('intelligent_fetchall_default_filter', 'filter_object', options)({});
 *
 *    ...
 *
 *    // Use it where tracking is relevant to the feature we registered:
 *    track('intelligent_fetchall_execution_time', {
 *      userId: flux.evaluate(userIdForFeature('intelligent_fetchall_default_filter'))
 *    });
 *
 * @param {String} feature
 * @param {String} userId
 */
export const setUserIdForFeature = (feature, userId) => {
  flux.dispatch(actionTypes.SET_USER_ID_FOR_FEATURE, { feature, userId });
  return userId;
};

/**
 * Given an map of userIds to forced variations, call setForcedVariation for each.
 * @param {Object} userToForceVariationsMap - E.g. { userId1: { exp1: var2 } }
 */
export function setForcedVariationsFromConfig(userToForceVariationsMap) {
  forEach(userToForceVariationsMap, (forcedVariation, userId) => {
    forEach(forcedVariation, (varId, expId) =>
      setForcedVariation(expId, varId, { userId }),
    );
  });
}

/**
 * Sets the plan product ids as attributes of the form "plan_product_id": "true".
 * E.g. "web_tier_3": "true".
 * @param {Array} planProductIds Plan product ids.
 */
export function setPlanProductIds(planProductIds) {
  if (!planProductIds) {
    return;
  }

  const attributes = planProductIds.reduce(
    (acc, plan) => ({ ...acc, [plan]: 'true' }),
    {},
  );

  setAttributes(attributes);
}

/**
 * Merges the attributes provided with the existing attributes
 * and sets them in the ReactSDK instance.
 * @param attributes
 */
export function setAttributes(attributes) {
  const clientInstance = getClientInstance();
  clientInstance.setUser({
    attributes: Object.assign(clientInstance.user.attributes, attributes),
  });
}

/**
 * Set's the userId for the ReactSDK.
 * @param {String} id
 */
export function setUserId(id) {
  getClientInstance().setUser({ id });
}

/**
 * If the current admin account is GAE admin, and we are in a dev or test
 * environment, returns the result of setForcedVariationsFromUrlParams given the
 * argument search string, otherwise returns null
 * @param {String} search
 * @returns {Array|null}
 */
export function setForcedVariationsFromUrlParamsIfAuthorized(search) {
  if (config.get('account_info') && config.get('account_info.is_admin')) {
    return setForcedVariationsFromUrlParams(search);
  }
  return null;
}

export function initializeAndObserveUserInfo(isImpersonating = false) {
  observeUserState();
  try {
    const accountIdString = String(config.get('admin_account.id', ''));
    // Set JS SDK userId using the customer accountId string
    setUserId(accountIdString);
    // Set JS SDK default attributes, most of which default to null
    setAttributes({
      // TODO(OASIS-3779) Legacy default String attributes. Migrate to updated ones below
      account_id: accountIdString,
      has_optimizely_email: fns.hasOptimizelyEmail(
        config.get('account_info.email', ''),
      ),
      user_id: config.get('account_info.unique_user_id', ''),
      plan_id: config.get('account_info.plan_id', ''),
      // Environment
      optly_config_env: config.get('env.ENVIRONMENT', null), // String
      optly_config_host_url: config.get('env.HOST_URL', null), // String
      is_local: !!config.get('env.IS_LOCAL', null), // Boolean
      is_gae_admin: !!config.get('account_info.is_admin', null), // Boolean
      is_impersonating: !!config.get('is_impersonating', null), // Boolean
      // Account
      account_admin_account_id: config.get('admin_account.id', null), // Number
      account_plan_id: config.get('account_info.plan_id', null), // String
      account_in_trial: !!config.get(
        'account_info.subscription.in_trial',
        null,
      ), // Boolean
      account_free_trial_days_remaining: config.get(
        'account_info.subscription.free_trial_days_remaining',
        null,
      ), // Number
      account_created_epoch_date: +new Date(
        config.get('admin_account.created', null),
      ), // Number
      account_has_optimizely_email: config
        .get('account_info.email', '')
        .includes('@optimizely.com'), // Boolean
      // Current Project
      current_project_experiment_confidence_threshold: config.get(
        'current_project.experiment_confidence_threshold',
        null,
      ), // Number (0-10000)
      current_project_code_revision: config.get(
        'current_project.code_revision',
        null,
      ), // Number
      current_project_milliseconds_since_code_last_modified:
        +new Date() -
          +new Date(config.get('current_project.code_last_modified')) || null, // Date String coerced to time elapsed in milliseconds Number
      // Current User
      current_user_id: config.get('account_info.unique_user_id', null), // String
      current_user_unique_id: config.get('account_info.unique_user_id', null), // String
      current_user_is_admin: !!config.get('account_info.is_user_admin', null), // Boolean
      current_user_country: config.get('current_user.country', null), // String
      current_user_two_factor_enrolled: !!config.get(
        'current_user.two_factor_enrolled',
        null,
      ), // Boolean
      current_user_email_change_requested: !!config.get(
        'current_user.email_change_requested',
        null,
      ), // Boolean
      segment_user_id: fns.generateUserId(
        config.get('account_info', {}),
        isImpersonating,
      ), // String
      turnstile_instance_id: config.get(
        'account_info.subscription.turnstile_instance_id',
        null,
      ), // Boolean
      featured_opti_id: config
        .get('account_info.permissions.userPermissions', [])
        .includes('FEATURED_OPTI_ID'),
      account_admin_center_role_id: config.get(
        'account_info.account_admin_center_role_id',
        null,
      ), // String
    });

    setForcedVariationsFromUrlParamsIfAuthorized(window.location.search);
    setForcedVariationsFromConfig(config.get('fullstack_forced_variations'));
    setPlanProductIds(
      config.get('account_info.subscription.product_ids', null),
    );

    // Some attribute values can change after the initial page request, so we must observe those
    setAttributes(flux.evaluate(CurrentProjectGetters.attributesForChampagne));
    flux.observe(
      CurrentProjectGetters.attributesForChampagne,
      attributesForChampagne => {
        setTimeout(() => setAttributes(attributesForChampagne));
      },
    );
  } catch (err) {
    consoleWarn.call(
      initializeAndObserveUserInfo,
      `Error initializing user info: ${err.message}`,
    );
  }
}

/**
 * Given an array of events, calls track on each event in the array
 * @param {Array} events
 */
export function trackEvents(events) {
  events.forEach(e => track(e));
}

/**
 * Track performance metrics for different split snippet implementation, with the Web visitor Id as the user
 */
export function trackSnippetPerformanceMetrics() {
  if (!window.optimizely) {
    return;
  }

  const userId = window.optimizely.get('visitor_id').randomId;
  let events = [];
  let supportsNavTiming = 'false';
  let firstContentfulPaintValue;
  let domProcessingTime;
  let domContentLoaded;
  let loadEventEnd;

  let navigationEntries = window.performance.getEntriesByType('navigation')[0];
  if (navigationEntries) {
    supportsNavTiming = 'true';
    domContentLoaded = navigationEntries.domContentLoadedEventEnd;
    loadEventEnd = navigationEntries.loadEventEnd;
    domProcessingTime =
      navigationEntries.domComplete - navigationEntries.responseEnd;
  } else {
    // Browser doesn't support PerformanceNavigationTiming
    navigationEntries = window.performance.timing;
    if (navigationEntries) {
      domContentLoaded =
        navigationEntries.domContentLoadedEventEnd -
        navigationEntries.navigationStart;
      loadEventEnd =
        navigationEntries.loadEventEnd - navigationEntries.navigationStart;
      domProcessingTime =
        navigationEntries.domComplete - navigationEntries.responseEnd;
    }
  }

  // If we got the values either way, add to events
  if (navigationEntries) {
    events = [
      [
        'domProcessingTime',
        {
          eventTags: { value: domProcessingTime },
          userId,
          attributes: { supportsNavTiming },
        },
      ],
      [
        'domContentLoaded',
        {
          eventTags: { value: domContentLoaded },
          userId,
          attributes: { supportsNavTiming },
        },
      ],
      [
        'loadEventEnd',
        {
          eventTags: { value: loadEventEnd },
          userId,
          attributes: { supportsNavTiming },
        },
      ],
    ];
  }

  const paintTiming = window.performance.getEntriesByName(
    'first-contentful-paint',
  )[0];
  if (paintTiming) {
    firstContentfulPaintValue = paintTiming.startTime;
    events.push([
      'first-contentful-paint',
      {
        eventTags: { value: firstContentfulPaintValue },
        userId,
        attributes: { supportsNavTiming },
      },
    ]);
  }

  forEach(events, event => track(...event)); //eslint-disable-line
}

export default {
  ...jsSDKLabActions,

  /* Optimizely Specific */
  getEventDispatcher,
  initializeAndObserveUserInfo,
  setAttributes,
  setForcedVariationsFromConfig,
  setForcedVariationsFromUrlParamsIfAuthorized,
  setLogLevel,
  setUserId,
  setUserIdForFeature,
  trackEvents,
  trackSnippetPerformanceMetrics,
};
