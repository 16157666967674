/**
 * Utility functions centralized to check if the Feature Flag is enabled.
 */
import {
  isFeatureEnabled,
  getFeatureVariableString,
} from '@optimizely/js-sdk-lab/src/actions';

export const WAREHOUSE_NATIVE_EXPERIMENTATION =
  'warehouse_native_experimentation';
export const WAREHOUSE_NATIVE_EXPERIMENTATION__DIRECT_NETSPRING_URL =
  'direct_netspring_url';

export const isWinnerRolloutFeatureEnabled = () =>
  isFeatureEnabled('winner_rollout_feature');

export const isWinnerRolloutM2FeatureEnabled = () =>
  isFeatureEnabled('winner_rollout_m2');

export const isCMABGroupedExperienceEnabled = () =>
  isFeatureEnabled('web-cmab-prioritize-experiences');

export const isExperimentOverviewEnabled = () =>
  isFeatureEnabled('web_experiment_overview');

export const isHypothesisLinkingEnabled = () =>
  isFeatureEnabled('hypothesis_linking');

export const isWarehouseNativeEnabled = () =>
  isFeatureEnabled(WAREHOUSE_NATIVE_EXPERIMENTATION);

export const getDirectNetspringUrl = () =>
  getFeatureVariableString(
    WAREHOUSE_NATIVE_EXPERIMENTATION,
    WAREHOUSE_NATIVE_EXPERIMENTATION__DIRECT_NETSPRING_URL,
  );

export const isComponentGroupingEnabled = () =>
  isFeatureEnabled('component_grouping');

export const isEditorMFEEnabled = () =>
  isFeatureEnabled('use_visual_editor_mfe');

export default {
  isCMABGroupedExperienceEnabled,
  isWinnerRolloutFeatureEnabled,
  isWinnerRolloutM2FeatureEnabled,
  isWarehouseNativeEnabled,
};
